import React from 'react'
import { addDays } from 'date-fns/addDays'

import { colors } from 'bl-common/src/constants/colors'
import ConfirmActionModal from 'bl-common/src/elements/ConfirmActionModal/ConfirmActionModal'
import { theme } from 'bl-common/src/styles/theme'
import { ScreenTheme } from 'bl-common/src/styles/types'
import {
  buildButton,
  buildCustomField,
  buildHeading,
  buildModalField,
  buildMultipleFields,
  buildScreenWithSidebarLayout,
  buildText,
  FlowControl,
  FlowSidebarLayout,
} from 'bl-flows-core'
import {
  CartType,
  ProductsOffer,
  useActivityProductAvailabilityQuery,
  useTransportationDataQuery,
} from 'bl-graphql'
import { activityDuration } from 'bl-utils/src/activityDuration'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { formatPrice } from 'bl-utils/src/currency/formatPrice'
import {
  getTimeFromDateTimeString,
  setDateTimeISOString,
} from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { PRODUCT_IDS } from 'bl-utils/src/ProductIds'
import { isTooLateToBookTransportation } from 'bl-utils/src/transportationUtils'

import { globalBookingMessages } from '../../../messages'
import { buildTransportationFlow } from '../../../subflows'
import { getSpaCartItinerary } from '../../../utils/getSpaCartItinerary'
import { isActivityInCart } from '../../../utils/isActivityInCart'
import { SpaCart } from '../components/SpaCart'
import { extrasMessages } from '../messages/extras'
import { extrasTransportationMessages } from '../messages/extrasTransportation'

const cartLayoutProps: FlowSidebarLayout['props'] = {
  id: 'confirm',
}

const triggerFunnelTransportationEvent = (
  control: FlowControl,
  isAddingTransportation?: boolean
) => {
  const flowState = control.flow.state
  const massage = isActivityInCart(control, 'massage')
  const float = isActivityInCart(control, 'float')
  const lava = isActivityInCart(control, 'lava')
  const spaRestaurant = isActivityInCart(control, 'spa-restaurant')
  const skincare = isActivityInCart(control, 'skincare')
  const busTransfer = isActivityInCart(control, 'busTransfer')
  const isRetreatSpaFlow = control.flow.setupHook?.isRetreatSpaFlow

  triggerEvent({
    event: 'funnel_transportation',
    BLType: isRetreatSpaFlow ? 'Day Visit Spa' : 'Day Visit',
    pageTitle: 'Transportation',
    pageCategory: 'book',
    Adult: flowState.guests.adults,
    ...(!isRetreatSpaFlow && { Children: flowState.guests.children }),
    Calendar: formatDateInUTC(
      new Date(flowState.calendar.arrivalDate),
      'yyyy-MM-dd'
    ),
    Package: isRetreatSpaFlow
      ? 'retreat spa'
      : control.flow.setupHook?.selectedPackage,
    Time: getTimeFromDateTimeString(flowState?.time?.selectedTime),
    ['In-water massage']: massage ? 'True' : 'False',
    ['Float Therapy']: float ? 'True' : 'False',
    ['Lava Restaurant']: lava ? 'True' : 'False',
    ['Spa Restaurant']: spaRestaurant ? 'True' : 'False',
    ['Skincare']: skincare ? 'True' : 'False',
    ['Transportation']:
      busTransfer || isAddingTransportation ? 'True' : 'False',
  })
}

const triggerAddToCartEvent = (
  control: FlowControl,
  spaFlowControl: FlowControl
) => {
  const adultPrice =
    control.screen.setupHook?.transportationData?.transportationData?.prices
      ?.adultPrice
  const adultQty = spaFlowControl.flow.state?.guests?.adults
  const childQty = spaFlowControl.flow.state?.guests?.children
  const exchangeRates = control.flow.setupHook?.exchangeRates

  const items = [
    {
      item_id: PRODUCT_IDS.TransferTwoWay,
      item_name: 'DBL Two way transfer',
      currency: 'EUR',
      item_brand: 'Blue Lagoon',
      item_category: 'Transportation',
      price: adultPrice ? calcPrice(adultPrice, exchangeRates?.EUR) : 0,
      quantity: adultQty,
    },
  ]

  if (childQty > 0) {
    items.push({
      item_id: PRODUCT_IDS.TransferTwoWay,
      item_name: 'DBL Two way transfer (child)',
      currency: 'EUR',
      item_brand: 'Blue Lagoon',
      item_category: 'Transportation',
      price: 0,
      quantity: childQty,
    })
  }

  triggerEvent({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'EUR',
      value: adultPrice
        ? Math.round(
            calcPrice(adultPrice, exchangeRates?.EUR) * (adultQty ?? 1)
          )
        : 0,
      items,
    },
  })
}

export const extrasTransportationScreen = ({
  screenTheme,
}: {
  screenTheme: ScreenTheme
}) => {
  return buildScreenWithSidebarLayout({
    id: 'extrasTransportation',
    subType: 'form',
    layoutProps: cartLayoutProps,
    columnStyle: true,
    theme: screenTheme,
    condition: control =>
      isTooLateToBookTransportation(
        new Date(control.flow.state?.time?.selectedTime)
      ) === false,
    route: control => {
      const selectedPackage =
        control?.flow?.setupHook?.selectedPackage || 'comfort'
      return {
        en:
          selectedPackage === 'retreat'
            ? 'transportation'
            : `${selectedPackage}/transportation`,
        is:
          selectedPackage === 'retreat'
            ? 'flutningur'
            : `${selectedPackage}/flutningur`,
      }
    },
    uiState: {
      showTransportationFlow: false,
    },
    setupHook: control => {
      const { data: transportationData } = useTransportationDataQuery()

      const selectedDate = new Date(control.flow.state?.time?.selectedTime)

      const fromDate = setDateTimeISOString(
        formatDateInUTC(selectedDate, 'yyyy-MM-dd'),
        '00:00'
      )

      const toDate = setDateTimeISOString(
        formatDateInUTC(addDays(selectedDate, 1), 'yyyy-MM-dd'),
        '03:00'
      )

      const transportationProductAvailability =
        useActivityProductAvailabilityQuery({
          variables: {
            input: {
              type: CartType.Dayspa,
              from: fromDate,
              to: toDate,
              productIds: [PRODUCT_IDS.TransferTwoWay],
            },
          },
        })?.data?.activityProductAvailability

      const isTooCloseToArrivalForTransportation =
        isTooLateToBookTransportation(
          new Date(control.flow.state?.time?.selectedTime)
        )

      return {
        isTooCloseToArrivalForTransportation,
        transportationData,
        transportationProductAvailability,
      }
    },
    breadcrumb: control => ({
      title: control.context.t(extrasMessages.info.breadcrumbTitle),
    }),
    fields: {
      main: [
        ...buildHeading({
          imageSrc:
            'https://images.ctfassets.net/w65k7w0nsb8q/Gq63Z33xg1qVJcOhsSW1h/f0dae113b065e9b5c74ef14c212d5580/DSC05327.jpg?w=600',
          title: control =>
            control.context.t(extrasTransportationMessages.info.title),

          includeBreadcrumb: true,
          showMobileCart: true,
        }),
        buildText({
          props: {
            type: 'text',
            value: control =>
              control.context.t(extrasTransportationMessages.info.subtitle),
          },
        }),
        buildMultipleFields({
          defaultValue: null,
          props: {
            fields: [
              buildButton({
                id: 'addTransportation',
                props: {
                  label: control => {
                    const currency = control.flow.setupHook?.currency
                    const exchangeRates = control.flow.setupHook?.exchangeRates
                    const price =
                      control.screen.setupHook?.transportationData
                        ?.transportationData?.prices?.adultPrice

                    return control.context.t(
                      extrasTransportationMessages.info.addTransportationModal,
                      {
                        formattedPrice: Number(price)
                          ? formatPrice(
                              calcPrice(price, exchangeRates?.[currency]),
                              currency,
                              false
                            )
                          : '—',
                      }
                    )
                  },
                  onClick: control =>
                    control.flow.setupHook?.cart?.items?.some(
                      item => item?.productId === PRODUCT_IDS.TransferTwoWay
                    )
                      ? control.screen.setUiState({
                          showTransportationDisclaimer: true,
                        })
                      : control.screen.setUiState({
                          showTransportationFlow: true,
                        }),
                },
              }),
              buildButton({
                id: 'continue',
                props: {
                  label: control =>
                    control.context.t(globalBookingMessages.buttons.continue),
                  preset:
                    screenTheme === 'retreat'
                      ? 'transparentWithBlueBorderOnDark'
                      : 'transparentWithBlueBorder',
                  isSubmit: true,
                  onClick: control => {
                    triggerFunnelTransportationEvent(control)
                  },
                },
                layout: {
                  spacing: {
                    mt: { xs: 1 },
                  },
                },
              }),
            ],
          },
          layout: {
            position: { sm: 'sticky', bmd: 'relative' },
            bottom: { sm: 24, bmd: 0 },
            marginTopAuto: { xs: true },
            width: { xs: `calc(100% + ${theme.spacing[2]})` },
            spacing: {
              ml: { xs: -1 },
              mr: { xs: -1 },
              pt: { xs: 2 },
              pb: { xs: 2, sm: 0 },
            },
          },
        }),
        buildCustomField({
          defaultValue: null,
          props: {
            render: control => {
              const onHide = () =>
                control.screen.setUiState({
                  showTransportationDisclaimer: false,
                })

              const isRetreat = screenTheme === 'retreat'

              return (
                <ConfirmActionModal
                  show={
                    control.screen.uiState.showTransportationDisclaimer === true
                  }
                  onHide={onHide}
                  title={control.context.t(
                    extrasTransportationMessages.info.ChangeBusBookingTitle
                  )}
                  message={control.context.t(
                    extrasTransportationMessages.info.ChangeBusBookingSubtitle
                  )}
                  buttonLabel={control.context.t(
                    extrasTransportationMessages.info.RemoveFromCartButton
                  )}
                  secondaryButtonLabel={control.context.t(
                    globalBookingMessages.buttons.cancel
                  )}
                  onConfirm={async () => {
                    const busCartItem =
                      control.flow.setupHook?.cart?.items?.find(
                        item => item.productId === PRODUCT_IDS.TransferTwoWay
                      )

                    if (!busCartItem) {
                      return true
                    }

                    try {
                      await control.flow.setupHook?.removeItemFromCart(
                        busCartItem?.id
                      )
                    } catch (err) {
                      console.error(err)
                      return false
                    }

                    return true
                  }}
                  onComplete={() => {
                    onHide()
                    control.screen.setUiState({
                      showTransportationFlow: true,
                    })
                  }}
                  onCancel={onHide}
                  backgroundColor={isRetreat && colors.darkmode}
                  textColor={isRetreat && colors.white}
                  secondaryButtonPreset={
                    isRetreat
                      ? 'transparentWithBlueBorderOnDark'
                      : 'transparentWithBlueBorder'
                  }
                />
              )
            },
          },
        }),
        buildModalField({
          props: {
            isOpen: control =>
              control.screen.uiState.showTransportationFlow === true,
            onHide: (_, control) =>
              control.screen.setUiState({
                showTransportationFlow: false,
              }),
          },
          children: control =>
            buildTransportationFlow(
              async (_, transportationFlowControl) => {
                const spaFlowControl =
                  transportationFlowControl.parentFlowControl
                const transportationProductAvailability: ProductsOffer[] =
                  spaFlowControl?.screen?.setupHook
                    ?.transportationProductAvailability

                const transportationState =
                  transportationFlowControl?.flow?.state

                const pickupAtAirport =
                  transportationState?.pickup?.type === 'airport'
                const dropoffAtAirport =
                  transportationState?.dropoff?.type === 'airport'
                const airportLocationId =
                  control.screen?.setupHook?.transportationData
                    ?.transportationData?.airport?.id

                const pickupDateAndTime = setDateTimeISOString(
                  formatDateInUTC(
                    new Date(transportationState?.pickupTime?.date),
                    'yyyy-MM-dd'
                  ),
                  transportationState?.pickupTime?.time
                )
                const dropoffDateAndTime = setDateTimeISOString(
                  formatDateInUTC(
                    new Date(transportationState?.departureTime?.date),
                    'yyyy-MM-dd'
                  ),
                  transportationState?.departureTime?.time
                )

                const inboundOffer = transportationProductAvailability?.find(
                  offer => {
                    return (
                      offer?.componentType === 'BL_InBound' &&
                      new Date(offer?.time).getTime() ===
                        new Date(pickupDateAndTime).getTime()
                    )
                  }
                )
                const outboundOffer = transportationProductAvailability?.find(
                  offer => {
                    return (
                      offer?.componentType === 'BL_OutBound' &&
                      new Date(offer?.time).getTime() ===
                        new Date(dropoffDateAndTime).getTime()
                    )
                  }
                )

                try {
                  await control.flow.setupHook?.addProductCartItem({
                    cartId: control.flow.setupHook?.cart?.id,
                    productId: PRODUCT_IDS.TransferTwoWay,
                    qty: spaFlowControl.flow.state?.guests?.adults,
                    type: CartType.Dayspa,
                    meta: {
                      numberOfAdults: spaFlowControl.flow.state?.guests?.adults,
                      numberOfChildren:
                        spaFlowControl.flow.state?.guests?.children,
                      inboundPickupLocationId: pickupAtAirport
                        ? airportLocationId
                        : transportationState?.pickupLocation?.location?.busStop
                            ?.id ||
                          transportationState?.pickupLocation?.location?.id,
                      inboundDepartureTime: inboundOffer?.time,
                      inboundComponentId: inboundOffer?.componentId,
                      outboundDropoffLocationId: dropoffAtAirport
                        ? airportLocationId
                        : transportationState?.dropoffLocation?.location
                            ?.busStop?.id ||
                          transportationState?.dropoffLocation?.location?.id,
                      outboundComponentId: outboundOffer?.componentId,
                      outboundDepartureTime: outboundOffer?.time,
                    },
                  })

                  triggerAddToCartEvent(control, spaFlowControl)
                  triggerFunnelTransportationEvent(control, true)

                  spaFlowControl.screen.setState({
                    pickupLocationName:
                      transportationState?.pickup?.type === 'airport'
                        ? transportationState?.pickup?.name
                        : transportationState.pickupLocation?.location?.busStop
                        ? transportationState.pickupLocation?.location?.busStop
                            .name
                        : transportationState.pickupLocation?.location?.name,
                    dropoffLocationName:
                      transportationState?.dropoff?.type === 'airport'
                        ? transportationState?.dropoff?.name
                        : transportationState.dropoffLocation?.location?.busStop
                        ? transportationState.dropoffLocation?.location?.busStop
                            .name
                        : transportationState.dropoffLocation?.location?.name,
                    pickupArea: transportationState?.pickup?.type,
                    dropoffArea: transportationState?.dropoff?.type,
                  })

                  return true
                } catch (err) {
                  console.error(err)
                  return false
                }
              },
              transportationFlowControl => {
                const spaFlowControl =
                  transportationFlowControl.parentFlowControl

                spaFlowControl.screen.setUiState({
                  showTransportationFlow: false,
                })

                transportationFlowControl.flow.setState({}, true)
                transportationFlowControl.screen.setState({}, true)
                transportationFlowControl.goToScreen(0)

                spaFlowControl.nextScreen()
              },
              {
                bookingInfo: {
                  entryDate: new Date(
                    control.flow.setupHook?.admissionItem?.meta?.arrivalTime
                  ),
                  adults: control.flow.state?.guests?.adults,
                  children: control.flow.state?.guests?.children,
                },
                transportation: {
                  price:
                    control.screen?.setupHook?.transportationData?.prices
                      ?.adultPrice,
                },
                activities: control.flow.setupHook?.cart?.items.map(item => {
                  return {
                    ...item,
                    duration: activityDuration[item?.productId],
                  }
                }),
                itineraryItems: getSpaCartItinerary(
                  control.flow.setupHook?.cart?.items || [],
                  control.context.t
                ),
                isRetreatSpaFlow:
                  control.flow.setupHook?.isRetreatSpaFlow && true,
              }
            ),
        }),
      ],
      right: [
        buildCustomField({
          defaultValue: null,
          props: {
            component: SpaCart,
          },
        }),
      ],
    },
  })
}
