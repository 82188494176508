import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { addDays } from 'date-fns/addDays'
import { addMinutes } from 'date-fns/addMinutes'
import { isAfter } from 'date-fns/isAfter'
import { isToday } from 'date-fns/isToday'
import { isWithinInterval } from 'date-fns/isWithinInterval'
import { subDays } from 'date-fns/subDays'
import isEqual from 'lodash/isEqual'

import { InfoBox } from 'bl-common/src/booking/InfoBox/InfoBox'
import { colors } from 'bl-common/src/constants/colors'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { theme } from 'bl-common/src/styles/theme'
import { ScreenTheme } from 'bl-common/src/styles/types'
import {
  buildBreadcrumbField,
  buildCustomField,
  buildDrawerField,
  buildHeading,
  buildScreenWithImageLayout,
  buildSelectDayVisitTimeField,
} from 'bl-flows-core'
import {
  AdmissionItem,
  CartItem,
  CartItemType,
  CartType,
  TransportationItem,
  useProductsAvailabilityQuery,
} from 'bl-graphql'
import { setDateTimeISOString } from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { getMembershipProductId } from 'bl-utils/src/membership'
import {
  bluelagoonDayVisitProductIds,
  PRODUCT_IDS,
} from 'bl-utils/src/ProductIds'

import { globalBookingMessages } from '../../../messages/global'
import { getSpaImageLayoutProps } from '../../../utils'
import { selectDayVisitTimeFieldMessages } from '../../../utils/selectDayVisitTimeFieldMessages'
import { ConfirmationDrawer } from '../components/ConfirmationDrawer'
import SubscriptionCardAccepted from '../components/SubscriptionCardAccepted'
import { commonMessages } from '../messages/common'
import { timeMessages } from '../messages/time'
import { getAdmissionTitle } from '../utils'

const isDefined = (value: unknown) => typeof value !== 'undefined'
const requiresReset = (
  previousValue: Date,
  nextValue: Date,
  cartItems: CartItem[]
) => {
  const previousActivityItems = cartItems.filter(
    item =>
      item.type === CartItemType.Massage ||
      item.type === CartItemType.Restaurant
  )
  const previousTransferItem = cartItems.find(
    item => item.type === CartItemType.Transfer
  )
  const allActivitiesAfterSelectedTime = previousActivityItems?.every(item =>
    isAfter(new Date((item as AdmissionItem).meta?.arrivalTime), nextValue)
  )
  const selectedTimeIsBetweenTransferItems =
    previousTransferItem &&
    isAfter(
      new Date(nextValue),
      new Date(
        (previousTransferItem as TransportationItem)?.meta?.inboundDepartureTime
      )
    ) &&
    isAfter(
      new Date(
        (
          previousTransferItem as TransportationItem
        )?.meta?.outboundDepartureTime
      ),
      new Date(nextValue)
    )

  const activityRequiresReset =
    previousActivityItems.length > 0 && !allActivitiesAfterSelectedTime
  const transferRequiresReset =
    !!previousTransferItem && !selectedTimeIsBetweenTransferItems

  return (
    isDefined(previousValue) &&
    !isEqual(previousValue, nextValue) &&
    (activityRequiresReset || transferRequiresReset)
  )
}

export const timeScreen = ({
  screenTheme,
  isRetreat,
}: {
  screenTheme: ScreenTheme
  isRetreat?: boolean
}) => {
  return buildScreenWithImageLayout({
    id: 'time',
    subType: 'form',
    route: control => {
      const selectedPackage =
        control?.flow?.setupHook?.selectedPackage || 'comfort'
      return {
        en: selectedPackage === 'retreat' ? 'time' : `${selectedPackage}/time`,
        is: selectedPackage === 'retreat' ? 'timi' : `${selectedPackage}/timi`,
      }
    },
    theme: screenTheme,
    queryParams: control => {
      return {
        arrivalDate: control?.flow?.state?.calendar?.arrivalDate
          ? formatDateInUTC(control?.flow?.state?.calendar?.arrivalDate)
          : formatDateInUTC(new Date()),
      }
    },
    layoutProps: control =>
      getSpaImageLayoutProps(
        control?.flow?.setupHook?.selectedPackage || 'comfort',
        'time',
        control
      ),
    setupHook: control => {
      // Fetch product availability for the selected date
      const arrivalDate = new Date(control.flow.state.calendar?.arrivalDate)

      // Make sure that we always have a valid date
      // fromTime should never be in the past, otherwise we get an error from the API.
      // Adding 5 minutes to the current time as a buffer.
      const fromTime = isToday(arrivalDate)
        ? formatDateInUTC(addMinutes(new Date(), 5), 'HH:mm')
        : '00:00'

      const toTime = '23:59'

      // Format the date
      const date = formatDateInUTC(arrivalDate, 'yyyy-MM-dd')

      // Format the date with the time
      const formattedFromTime = setDateTimeISOString(date, fromTime)
      const formattedToTime = setDateTimeISOString(date, toTime)

      const { mediaBmd } = useBreakpoints()

      const {
        loading: isProductsAvailabilityDataLoading,
        error: productsAvailabilityError,
        data: productsAvailabilityData,
        refetch: refetchProductsAvailability,
      } = useProductsAvailabilityQuery({
        variables: {
          input: {
            type: CartType.Dayspa,
            productIds: isRetreat
              ? [PRODUCT_IDS.SpaRetreat]
              : bluelagoonDayVisitProductIds,
            from: formattedFromTime,
            to: formattedToTime,
            isMembership:
              control.flow.setupHook?.cart?.membership?.membershipToken != null,
          },
        },
      })

      const membershipProductId = getMembershipProductId(
        control.flow.setupHook?.cart?.membership
      )

      const packageProductNo = useMemo(() => {
        if (productsAvailabilityData == null) {
          return null
        }

        return control.flow?.setupHook?.selectedPackage === 'premium'
          ? PRODUCT_IDS.SpaPremium
          : control.flow?.setupHook?.selectedPackage === 'comfort'
          ? PRODUCT_IDS.SpaComfort
          : control.flow?.setupHook?.selectedPackage === 'signature'
          ? PRODUCT_IDS.SpaSignature
          : control.flow?.setupHook?.selectedPackage === 'retreat'
          ? PRODUCT_IDS.SpaRetreat
          : membershipProductId
      }, [productsAvailabilityData, control.flow?.setupHook?.selectedPackage])

      return {
        packageProductNo,
        isProductsAvailabilityDataLoading,
        productsAvailabilityError,
        productsAvailabilityData,
        refetchProductsAvailability,
        mediaBmd,
      }
    },
    breadcrumb: control => {
      return {
        title: control.context.t(timeMessages.info.breadcrumbTitle),
        value:
          control.flow.state?.time?.selectedTime &&
          control.context.t(timeMessages.info.breadcrumbSubtitle, {
            formattedTime:
              control.flow.state?.time?.selectedTime &&
              formatDateInUTC(
                new Date(control.flow.state?.time?.selectedTime),
                'HH:mm'
              ),
          }),
      }
    },
    uiState: {
      isConfirmModalOpen: false,
      currentVisibleArrivalDate: control =>
        control.flow.stateRef.current?.calendar?.arrivalDate,
    },
    fields: {
      main: [
        buildBreadcrumbField({
          props: {
            breadcrumb: control => control.screen.breadcrumb,
          },
          layout: {
            spacing: {
              mb: { xs: 3 },
            },
          },
        }),
        buildCustomField({
          condition: control => {
            return (
              control.flow.setupHook?.cart?.membership?.membershipToken != null
            )
          },
          props: {
            render: () => {
              const { formatMessage } = useIntl()

              return (
                <SubscriptionCardAccepted
                  acceptedText={formatMessage(
                    globalBookingMessages.text.winterCardAccepted
                  )}
                  removeButtonLabel={formatMessage(
                    globalBookingMessages.buttons.remove
                  )}
                />
              )
            },
          },
        }),
        ...buildHeading({
          title: control =>
            getAdmissionTitle(
              control,
              control?.flow?.setupHook?.selectedPackage
            ),
          subTitle: control =>
            control.context.t(
              control.flow.setupHook?.cart?.membership?.membershipToken != null
                ? timeMessages.info.subtitleMembership
                : isRetreat
                ? timeMessages.info.subtitleRetreat
                : timeMessages.info.subtitle
            ),
          removeMarginTop: true,
        }),
        buildCustomField({
          defaultValue: null,
          condition: control => !control.screen.setupHook?.mediaBmd,

          props: {
            component: props => {
              const spaFlowSettings =
                props.control.context?.spaFlowSettings?.find(flowSettings => {
                  if (
                    props.control?.flow?.setupHook?.selectedPackage ===
                    'subscription'
                  ) {
                    return flowSettings?.fields?.admissionType === 'comfort'
                  }

                  return (
                    flowSettings?.fields?.admissionType ===
                    props.control?.flow?.setupHook?.selectedPackage
                  )
                })

              return (
                <div
                  style={{
                    paddingTop: theme.spacing[1],
                    paddingBottom: theme.spacing[2.5],
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing[1],
                  }}
                >
                  {spaFlowSettings?.fields?.openingHours?.map(item => {
                    if (
                      item?.fields?.dates?.some(({ fields: { from, to } }) =>
                        isWithinInterval(
                          new Date(
                            props.control.flow.state?.calendar?.arrivalDate
                          ),
                          {
                            start: new Date(from),
                            end: new Date(to),
                          }
                        )
                      )
                    ) {
                      return (
                        <InfoBox
                          key={item?.fields?.name}
                          title={item?.fields?.title}
                          content={item?.fields?.content}
                          backgroundColor={isRetreat && colors.darkmodeOffset}
                          textColor={isRetreat && colors.white}
                        />
                      )
                    }
                  })}
                </div>
              )
            },
          },
        }),
        buildSelectDayVisitTimeField({
          id: 'selectedTime',
          defaultValue: null,
          props: {
            isLoadingAvailability: control =>
              control.screen.setupHook?.isProductsAvailabilityDataLoading,
            hasAvailabilityError: control =>
              control.screen.setupHook?.productsAvailabilityError,
            availabilityData: control =>
              control.screen.setupHook?.productsAvailabilityData,
            refetchAvailability: control =>
              control.screen.setupHook?.refetchProductsAvailability,
            getAdmissionTitle: () => getAdmissionTitle,
            packageProductNo: control =>
              control.screen.setupHook?.packageProductNo,
            guestCount: control => control.flow.state?.guests?.adults,
            date: control => control.flow.state?.calendar?.arrivalDate,
            showCurrencySelector: true,
            showDatePicker: true,
            isMembershipInCart: control =>
              control.flow.setupHook?.isMembershipInCart,
            membershipProductId: control =>
              control.flow.setupHook?.membershipProductId,
            onPrevDayClick: control =>
              control.flow.setState({
                calendar: {
                  arrivalDate: subDays(
                    new Date(control.flow.state?.calendar?.arrivalDate),
                    1
                  ),
                },
              }),
            onNextDayClick: control =>
              control.flow.setState({
                calendar: {
                  arrivalDate: addDays(
                    new Date(control.flow.state?.calendar?.arrivalDate),
                    1
                  ),
                },
              }),
            onClick: async (resultState, control) => {
              if (
                control.flow.setupHook?.cart?.items?.length &&
                requiresReset(
                  new Date(control.flow.stateRef.current.time?.selectedTime),
                  new Date(resultState?.selectedTime),
                  control.flow.setupHook?.cart?.items
                )
              ) {
                const shouldContinue = await control.confirm(
                  control.context.t(commonMessages.warnings.modalResetState),
                  {
                    confirmLabel: control.context.t(
                      commonMessages.info.modalContinue
                    ),
                    cancelLabel: control.context.t(
                      commonMessages.info.modalCancel
                    ),
                    title: control.context.t(
                      commonMessages.info.modalTitleUpdateBooking
                    ),
                  }
                )

                if (!shouldContinue) {
                  return
                }
              }

              control.screen.setState({
                ...resultState,
              })

              control.screen.setUiState({
                isConfirmModalOpen: true,
              })
            },
            messages: selectDayVisitTimeFieldMessages,
          },
          validation: {
            renderErrorInFieldRenderer: false,
            validator: (_, __, control) => {
              const admissionItem = control.flow.setupHook?.cart?.items?.find(
                item => item.type === CartItemType.Admission
              )

              if (
                isRetreat &&
                admissionItem?.productId !== PRODUCT_IDS.SpaRetreat &&
                !control.screen.uiStateRef?.current?.isAddingToCart
              ) {
                // we are not rendering this validation, since its purpose is just to stop the user
                // on this screen if they have an admission item in their cart that is not Retreat
                // that way they are forced to add the Retreat item to the cart to replace the old one
                return 'Cannot continue with wrong admission item'
              }
            },
          },
        }),
        buildDrawerField({
          props: {
            isOpen: control => control.screen.uiState.isConfirmModalOpen,
            onHide: (_, control) => {
              control.screen.setUiState({
                isConfirmModalOpen: false,
              })
            },
          },
          children: [
            buildCustomField({
              defaultValue: null,
              props: {
                component: ConfirmationDrawer,
              },
            }),
          ],
        }),
      ],
      rightBottom: [
        buildCustomField({
          defaultValue: null,
          props: {
            component: props => {
              const spaFlowSettings =
                props.control.context?.spaFlowSettings?.find(flowSettings => {
                  if (
                    props.control?.flow?.setupHook?.selectedPackage ===
                    'subscription'
                  ) {
                    return flowSettings?.fields?.admissionType === 'comfort'
                  }

                  return (
                    flowSettings?.fields?.admissionType ===
                    props.control?.flow?.setupHook?.selectedPackage
                  )
                })

              return (
                <div
                  style={{
                    padding: theme.spacing[4],
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing[1],
                  }}
                >
                  {spaFlowSettings?.fields?.openingHours?.map(item => {
                    if (
                      item?.fields?.dates?.some(({ fields: { from, to } }) =>
                        isWithinInterval(
                          new Date(
                            props.control.flow.state?.calendar?.arrivalDate
                          ),
                          {
                            start: new Date(from),
                            end: new Date(to),
                          }
                        )
                      )
                    ) {
                      return (
                        <InfoBox
                          key={item?.fields?.name}
                          title={item?.fields?.title}
                          content={item?.fields?.content}
                          backgroundColor={isRetreat && colors.darkmode}
                          textColor={isRetreat && colors.white}
                        />
                      )
                    }
                  })}
                </div>
              )
            },
          },
        }),
      ],
    },
  })
}
