import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import ConfirmActionModal from 'bl-common/src/elements/ConfirmActionModal/ConfirmActionModal'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { useCartContext } from 'bl-common/src/hooks/useCartContext'
import { theme } from 'bl-common/src/styles/theme'
import { SimpleForm } from 'bl-common/src/units/Form/SimpleForm'
import { call } from 'bl-common/src/utils/call'
import { media, mediaObj } from 'bl-common/src/utils/media'
import { FlowComponent } from 'bl-flows-core'
import {
  MembershipType,
  useValidateMembershipMutation,
  useVerifyMembershipMutation,
  ValidatedMembership,
  ValidateMembershipMutation,
  VerifyMembershipMutation,
} from 'bl-graphql'
import { getMyBookingLink } from 'bl-utils/src/routing/getMyBookingLink'

import { globalBookingMessages } from '../../../messages'
import { admissionMessages } from '../messages/admission'

const Container = styled.div`
  max-width: 544px;
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  padding-left: ${({ theme }) => `${theme.spacing[2.5]}`};
  padding-right: ${({ theme }) => `${theme.spacing[2.5]}`};

  ${media.bmd(css`
    padding-left: 0;
    padding-right: 0;
  `)}

  ${media.md(css`
    min-height: 250px;
  `)}
`
const ButtonInline = styled.button`
  display: inline;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;

  :disabled {
    opacity: 0.5;
  }
`

const DrawerForm = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const DrawerWrapper = styled.div<{ showUpsell?: boolean }>(
  ({ showUpsell }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'stretch',
    flexDirection: 'column',
    rowGap: theme.spacing[3],

    [mediaObj.md]: {
      justifyContent: showUpsell ? 'space-between' : 'center',
      flexDirection: 'row',
    },
  })
)

const validatePhone = (values, t) => {
  let hasErrors = false
  const errors = { phone: '' }

  if (!values.phone) {
    errors.phone = t(globalBookingMessages.errors.phoneNumberRequired)
    hasErrors = true
  }

  return hasErrors ? errors : {}
}

const validateCode = (values, t) => {
  let hasErrors = false
  const errors = { code: '' }

  if (!values.code) {
    errors.code = t(globalBookingMessages.errors.confirmationCodeRequired)
    hasErrors = true
  }

  return hasErrors ? errors : {}
}

export const SubscriptionDrawer = ({
  control,
  membershipType,
  onContinue,
}: FlowComponent & {
  membershipType: MembershipType
  onContinue: () => void
}) => {
  const { cart, addMembershipToken, addSpaCustomerInfo, resetCart } =
    useCartContext()
  const [validateMembership] = useValidateMembershipMutation()
  const [verifyMembership] = useVerifyMembershipMutation()

  const [errors, setErrors] = useState(null)
  const [phone, setPhone] = useState('')
  const [sendingCode, setSendingCode] = useState(false)

  const [showPhone, setShowPhone] = useState('phone')

  const checkPhone = async values => {
    setErrors(null)
    const [data, error] = await call<VerifyMembershipMutation>(
      verifyMembership({
        variables: {
          info: {
            phoneNumber: values.phone,
            membershipType,
          },
        },
      })
    )

    if (error) {
      setErrors({
        phone: control.context.t(globalBookingMessages.errors.generalTryAgain),
      })
      return
    }

    setPhone(values.phone)
    return data.verifyMembership.valid
  }

  const sendNewCode = async () => {
    setErrors(null)
    setSendingCode(true)
    const [, error] = await call<VerifyMembershipMutation>(
      verifyMembership({
        variables: {
          info: {
            phoneNumber: phone,
            membershipType,
          },
        },
      })
    )

    setSendingCode(false)

    if (error) {
      switch (error?.code) {
        default:
          setErrors({
            code: control.context.t(
              globalBookingMessages.errors.generalTryAgain
            ),
          })
          break
      }
      return
    }
  }

  const checkCode = async values => {
    setErrors(null)
    const [data, error] = await call<ValidateMembershipMutation>(
      validateMembership({
        variables: {
          info: {
            phoneNumber: phone,
            code: values.code.toString(),
            membershipType,
          },
        },
      })
    )

    if (error) {
      switch (error?.code) {
        case 'booking.api.errors.invalid.validation.code':
          setErrors({
            code: control.context.t(
              globalBookingMessages.errors.invalidConfirmationCode
            ),
          })
          break
        default:
          setErrors({
            code: control.context.t(
              globalBookingMessages.errors.generalTryAgain
            ),
          })
          break
      }
      return
    }

    await onVerified(data.validateMembership)

    return true
  }

  const onVerified = async (data: ValidatedMembership) => {
    if (cart?.items?.length > 0) {
      await resetCart()
    }

    await addMembershipToken(data.membershipValidationToken)
    await addSpaCustomerInfo({
      customer: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        // We currently only support IS nationality for memberships
        nationality: 'IS',
      },
    })
  }

  const validatePhoneNumber = values => {
    setErrors(null)

    return validatePhone(values, control.context.t)
  }
  const validateConfirmationCode = values => {
    setErrors(null)
    return validateCode(values, control.context.t)
  }

  const showErrorInModal =
    errors?.code === 'booking.api.errors.booking.limit.exceeded'

  return (
    <DrawerWrapper>
      <ConfirmActionModal
        show={showErrorInModal}
        onHide={() => setErrors(null)}
        title={'Of margar bókanir'}
        message={control.context.t(
          admissionMessages.errors.membershipLimitExceeded
        )}
        buttonLabel={control.context.t(
          globalBookingMessages.buttons.loginToMyBluelagoon
        )}
        onConfirm={() => (window.location.href = getMyBookingLink())}
      />
      <Container>
        {showPhone === 'phone' ? (
          <DrawerForm
            key="phone"
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -20, opacity: 0 }}
            transition={{ duration: 0.4, ease: [0.24, 0.28, 0.63, 1] }}
          >
            <Type
              as="h4"
              weight="bold"
              size={{ xs: 20, md: 30 }}
              bottom={{ xs: 2.5, md: 1 }}
            >
              {control.context.t(
                globalBookingMessages.text.membershipLoginTitle
              )}
            </Type>
            <SimpleForm
              form={{
                fields: [
                  {
                    type: 'phone',
                    key: 'phone',
                    name: control.context.t(
                      globalBookingMessages.labels.membershipPhoneNumber
                    ),
                  },
                ],
              }}
              initialValues={{ phone: '' }}
              validate={validatePhoneNumber}
              onSubmit={checkPhone}
              onComplete={() => setShowPhone('code')}
              buttonText={control.context.t(
                globalBookingMessages.buttons.membershipLogin
              )}
              buttonSize="small"
              parentErrors={showErrorInModal ? undefined : errors}
              focusFirstInput
              validateOnBlur={false}
            />
          </DrawerForm>
        ) : (
          <DrawerForm
            key="code"
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -20, opacity: 0 }}
            transition={{ duration: 0.4, ease: [0.24, 0.28, 0.63, 1] }}
          >
            <Type
              as="h4"
              weight="bold"
              size={{ xs: 20, md: 30 }}
              bottom={{ xs: 2.5, md: 1 }}
            >
              {control.context.t(
                globalBookingMessages.text.enterConfirmationNumber
              )}
            </Type>
            <SimpleForm
              form={{
                fields: [
                  {
                    type: 'number',
                    key: 'code',
                    name: control.context.t(
                      globalBookingMessages.labels.codeSentTo,
                      {
                        phone,
                      }
                    ),
                  },
                ],
              }}
              initialValues={{ code: '' }}
              validate={validateConfirmationCode}
              onSubmit={checkCode}
              onComplete={() => onContinue()}
              buttonText={control.context.t(
                globalBookingMessages.buttons.confirm
              )}
              buttonSize="small"
              parentErrors={showErrorInModal ? undefined : errors}
              focusFirstInput
              validateOnBlur={false}
            />
            <div style={{ display: 'flex' }}>
              <Type inline right={0.5} preset="textSmall">
                {control.context.t(globalBookingMessages.labels.noCode)}
              </Type>
              <ButtonInline onClick={sendNewCode} disabled={sendingCode}>
                <Type color={colors.dark} underline preset="textSmall">
                  {control.context.t(globalBookingMessages.buttons.sendAgain)}
                </Type>
              </ButtonInline>
              <ButtonInline
                style={{
                  marginLeft: 'auto',
                }}
                onClick={() => setShowPhone('phone')}
              >
                <Type color={colors.dark} underline preset="textSmall">
                  {control.context.t(
                    globalBookingMessages.buttons.changeNumber
                  )}
                </Type>
              </ButtonInline>
            </div>
          </DrawerForm>
        )}
      </Container>
    </DrawerWrapper>
  )
}

export default SubscriptionDrawer
