import React, { CSSProperties } from 'react'
import { FC, ReactNode } from 'react'
import { Document } from '@contentful/rich-text-types'
import styled from 'styled-components'

import { colors } from '../../constants/colors'
import { Type } from '../../elements/Typography/Typography'
import {
  DocumentWrapper,
  RichTextRenderer,
} from '../../richText/RichTextRenderer'

const Box = styled.div<{ backgroundColor?: string; textColor?: string }>(
  ({ backgroundColor, textColor, theme }) => ({
    background: backgroundColor ?? 'rgba(255, 255, 255, 0.8)',
    color: textColor ?? colors.midGrey,
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)',
    padding: `${theme.spacing[2]} ${theme.spacing[3]}`,
    backdropFilter: 'blur(8px)',
    borderRadius: theme.borderRadius?.elements ?? 0,
    [DocumentWrapper]: {
      marginTop: 0,
    },
  })
)

interface InfoBoxProps {
  title: string
  content: string | Document
  children?: ReactNode
  backgroundColor?: string
  textColor?: string
  style?: CSSProperties
}

export const InfoBox: FC<InfoBoxProps> = ({
  title,
  content,
  children,
  backgroundColor,
  textColor,
  style,
}) => (
  <Box backgroundColor={backgroundColor} textColor={textColor} style={style}>
    {title && (
      <Type
        as="h3"
        preset="headlineSmall"
        bottom={{ xs: 1, md: 0.5 }}
        weight="bold"
      >
        {title}
      </Type>
    )}
    {typeof content === 'string' ? (
      <Type preset="text" bottom={{ xs: 1, md: 0.5 }}>
        {content}
      </Type>
    ) : (
      <RichTextRenderer document={content} />
    )}
    {children}
  </Box>
)
